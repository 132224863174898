<template>
    <div
        v-if="filterFieldsUsed || filters.sort || filters.search"
        class="grid-filters-results"
    >
        <template v-for="filter in filterFieldsUsed">
            <!-- Select -->
            <span
                v-if="filter.type.startsWith('select')"
                :key="`filter-${filter.id}`"
            >
                <div
                    v-for="value in filter.value"
                    :key="`active-filter-${filter.id}-${value}`"
                    class="grid-filter-item"
                >
                    <span>{{ `${filter.label}: ${cleanLabel(filter.options[value])}` }}</span>
                    <i
                        class="icon md-close"
                        @click="removeFilter(filter.slug, value)"
                    />
                </div>
            </span>

            <!-- Date filters -->
            <div
                v-else-if="isDate(filter.type)"
                :key="`fl-${filter.id}`"
                class="grid-filter-item"
            >
                <span>
                    {{ `${filter.label}: ` }}
                    {{ filter.value | toJalaali('jYYYY/jM/jD') | digits }}
                </span>
                <i
                    class="icon md-close"
                    @click="removeFilter(filter.slug)"
                />
            </div>

            <!-- Other filters -->
            <div
                v-else
                :key="`filter-${filter.id}`"
                class="grid-filter-item"
            >
                <span>{{ `${filter.label}: ${cleanLabel(filter.value)}` | digits }}</span>
                <i
                    class="icon md-close"
                    @click="removeFilter(filter.slug)"
                />
            </div>
        </template>

        <!-- Search filter -->
        <div
            v-if="searchLabel"
            class="grid-filter-item simple"
        >
            <span>{{ `${$t('fields.search')}: ${searchLabel}` | digits }}</span>
        </div>

        <!-- Sort filters -->
        <div class="grid-filter-item simple">
            <span>{{ `${$t('fields.sort')}: ${sortLabel} - ${orderLabel}` }}</span>
        </div>
    </div>
</template>

<script>
    import { truncate } from '@nodes/helpers/string';

    export default {

        name: 'GridFilters',

        props: {
            grid       : Object,
            filters    : Object,
            filtersForm: Array,
        },

        computed: {

            /**
             * Return used filters
             */
            filterFieldsUsed() {
                const fields = [];

                if (this.grid.header.has_filters && this.filtersForm && this.filtersForm.length) {
                    this.filtersForm.forEach((item) => {
                        const queryItem = this.get(this.$route.query, item.name);
                        if (this.filters && !this.filters.hasOwnProperty(item.name)) {
                            this.$set(this.filters, item.name, null);
                        }
                        if (queryItem && ((Array.isArray(queryItem) && queryItem.length) || (!Array.isArray(queryItem)))) {
                            const value = this.getValue(item);
                            const options = this.makeOptions(item);

                            fields.push({
                                id   : item.id,
                                slug : item.name,
                                label: item.label,
                                type : item.component_name,
                                value,
                                options,
                            });
                        }
                    });
                }

                return fields;
            },

            /**
             * Return sort options
             */
            sortOptions() {
                const sortFields = this.get(this.grid, 'header.sort_fields');
                if (sortFields) {
                    return Object.keys(sortFields).map((value) => ({
                        label: sortFields[value].label,
                        value,
                    }));
                }

                return [];
            },

            /**
             * Order options
             */
            orderOptions() {
                return [
                    {
                        label: this.$t('fields.desc'),
                        value: 'desc',
                    },
                    {
                        label: this.$t('fields.asc'),
                        value: 'asc',
                    },
                ];
            },

            /**
             * Return label of sort
             */
            sortLabel() {
                const item = this.sortOptions.find((el) => el.value === this.filters.sort);
                return item ? item.label : '';
            },

            /**
             * Return label of sort
             */
            orderLabel() {
                return this.$t(`fields.${this.filters.order}`);
            },

            /**
             * Return label of search
             */
            searchLabel() {
                return this.get(this.filters, 'search');
            },

        },

        methods: {

            /**
             * Clean label
             *
             * @param val
             */
            cleanLabel(val) {
                return val ? truncate(val.toString().replace(/^(\s|-)*/g, ''), 30) : val;
            },

            /**
             * Remove filter
             *
             * @param slug
             * @param value
             */
            removeFilter(slug, value) {
                this.$wait.start('list-loading');
                const filters = this.cloneDeep(this.filters);
                this.$set(filters, 'refresh', 'list');
                if (!value) {
                    this.$set(filters, slug, '');
                } else if (this.filters[slug] && Array.isArray(this.filters[slug]) && this.filters[slug].length) {
                    const filtered = this.filters[slug].filter((item) => item !== value);
                    this.$set(filters, slug, filtered);
                } else {
                    this.$set(filters, slug, null);
                }

                this.$emit('refresh', filters);
            },

            /**
             * Convert options to key value
             *
             * @param options
             */
            convertOptions(options) {
                return options.reduce((obj, item) => {
                    // eslint-disable-next-line no-param-reassign
                    obj[item.value || item.id] = item.label || item.title;
                    return obj;
                }, {});
            },

            /**
             * Get value of selected
             *
             * @param item
             */
            getValue(item) {
                let value = this.filters[item.name];

                if (item.component_name.startsWith('select') && !Array.isArray(value)) {
                    value = [value];
                } else if (value && typeof value === 'object' && !Array.isArray(value)) {
                    value = value.label || value.title;
                }

                return value;
            },

            /**
             * Make filter options
             *
             * @param item
             */
            makeOptions(item) {
                let { options } = item;

                if (item.component_name === 'select' || item.component_name === 'select-nested') {
                    options = this.convertOptions(options);
                } else if (item.component_name === 'select-tag') {
                    options = options.reduce((acc, el) => {
                        if (!el.labels || !el.labels.length) {
                            return null;
                        }

                        // eslint-disable-next-line no-param-reassign
                        acc = { ...acc, ...this.convertOptions(el.labels) };
                        return acc;
                    }, {});
                }

                return options;
            },

            /**
             * Check if filter is date
             *
             * @param type
             */
            isDate(type) {
                return type === 'date' || type === 'datetime' || type === 'time';
            },

        },

    };
</script>
